import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import {
  SearchMap,
  ModalInMobile,
  Page,
  LayoutWrapperTopbar,
  LayoutSingleColumn,
  LayoutWrapperMain,
} from '../../components';
import { TopbarContainer } from '../../containers';
import { searchMapListings, setActiveListing } from './SearchPage.duck';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import MainPanel from './MainPanel';
import SideNav from '../../components/SideNav/SideNav';
import css from './SearchPage.module.css';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

const PRIORITY_VEHICLE_IDS = [
  '66f6ecfa-fc07-4acf-a0d3-93f30598aac9',
  '6777efe3-7ea8-452d-b14a-39dffa690496',
  '675a3074-2183-48d5-af04-f48718c11662',
  '6783da32-3379-4c57-aaa6-4cbf8d0f72c3',
  '66f3048c-6ff1-413d-98ec-5171b0644044',
  '66db7c5e-f413-4030-9a70-6afe440cbea3',
  '67251c89-8669-42a2-8e87-e78b6fafb94b',
  '66c8a1c8-2542-4319-a55b-eb3456010ae5',
  '67463314-9b8b-4ca7-b838-dc95f4ac0148',
  '66770fb7-f0cc-4f13-b041-9ee22f98a984',
  '66e1a1ec-4b8f-4c0f-b450-084a6c155ce2',
  '67250e87-408f-4662-a2a0-2eff25dc7643',
  '6643639e-22c0-4664-b242-4cd8eb4f6d42',
  '66a12b60-6bad-4cd6-b11c-06d9a360be67',
  '66be2511-69fd-461e-841e-5685cc7cf973',
  '675db86e-ee07-40ad-a149-3069074d8da7',
  '6712850e-a58b-46c1-ac5a-5db6261d36ce',
  '67835a81-5007-48bc-ae32-e7554efa57a2',
  '66edff15-029f-4985-90b7-0dd5c29aa0e3',
  '67aa7a34-849b-4dcf-829b-1362ab5659e3',
];

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfig),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  render() {
    const {
      intl,
      listings,
      filterConfig,
      sortConfig,
      history,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    const mapComponent = (
      <div className={css.mapWrapper}>
        {shouldShowSearchMap ? (
          <SearchMap
            reusableContainerClassName={css.map}
            activeListingId={activeListingId}
            bounds={bounds}
            center={origin}
            isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
            location={location}
            listings={mapListings || []}
            onMapMoveEnd={this.onMapMoveEnd}
            onCloseAsModal={() => {
              onManageDisableScrolling('SearchPage.map', false);
            }}
            messages={intl.messages}
          />
        ) : null}
      </div>
    );

    const pageName = ['Vehículos'];
    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description="Explora Más"
        title="Explora Más"
        schema={schema}
        className={css.pageRoot}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer
              className={topbarClasses}
              currentPage="SearchPage"
              currentSearchParams={urlQueryParams}
              pageName={pageName}
            />
            <div className={css.sideNav}>
              <SideNav />
            </div>
          </LayoutWrapperTopbar>
          <LayoutWrapperMain className={css.staticPageWrapper}>
            <div className={css.mobile}>
              <p className={css.mobileText}>Vehículos</p>
            </div>
            <div className={this.props.isAuthenticated ? css.container : css.noWrapper}>
              {isMobileLayout ? (
                <>
                  {/* MOBILE */}
                  <MainPanel
                    urlQueryParams={validQueryParams}
                    listings={listings}
                    searchInProgress={searchInProgress}
                    searchListingsError={searchListingsError}
                    searchParamsAreInSync={searchParamsAreInSync}
                    onActivateListing={onActivateListing}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onOpenModal={this.onOpenMobileModal}
                    onCloseModal={this.onCloseMobileModal}
                    onMapIconClick={onMapIconClick}
                    pagination={pagination}
                    searchParamsForPagination={parse(location.search)}
                    showAsModalMaxWidth={MODAL_BREAKPOINT}
                    history={history}
                  />
                  <ModalInMobile
                    className={css.mapPanel}
                    id="SearchPage.map"
                    isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                    onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
                    showAsModalMaxWidth={MODAL_BREAKPOINT}
                    onManageDisableScrolling={onManageDisableScrolling}
                  >
                    <div className={css.mapWrapper}>
                      {shouldShowSearchMap ? (
                        <SearchMap
                          reusableContainerClassName={css.map}
                          activeListingId={activeListingId}
                          bounds={bounds}
                          center={origin}
                          isSearchMapOpenOnMobile={this.state.isSearchMapOpenOnMobile}
                          location={location}
                          listings={mapListings || []}
                          onMapMoveEnd={this.onMapMoveEnd}
                          onCloseAsModal={() => {
                            onManageDisableScrolling('SearchPage.map', false);
                          }}
                          messages={intl.messages}
                        />
                      ) : null}
                    </div>
                  </ModalInMobile>
                </>
              ) : (
                <>
                  {/* DESKTOP */}
                  <MainPanel
                    urlQueryParams={validQueryParams}
                    listings={listings}
                    searchInProgress={searchInProgress}
                    searchListingsError={searchListingsError}
                    searchParamsAreInSync={searchParamsAreInSync}
                    onActivateListing={onActivateListing}
                    onManageDisableScrolling={onManageDisableScrolling}
                    onOpenModal={this.onOpenMobileModal}
                    onCloseModal={this.onCloseMobileModal}
                    onMapIconClick={onMapIconClick}
                    pagination={pagination}
                    searchParamsForPagination={parse(location.search)}
                    showAsModalMaxWidth={MODAL_BREAKPOINT}
                    history={history}
                    mapComponent={mapComponent}
                    isMobileLayout={isMobileLayout}
                  />
                  {/* <ModalInMobile
    className={css.mapPanel}
    id="SearchPage.map"
    isModalOpenOnMobile={this.state.isSearchMapOpenOnMobile}
    onClose={() => this.setState({ isSearchMapOpenOnMobile: false })}
    showAsModalMaxWidth={MODAL_BREAKPOINT}
    onManageDisableScrolling={onManageDisableScrolling}
  > */}

                  {/* </ModalInMobile> */}
                </>
              )}
            </div>
          </LayoutWrapperMain>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  activeListingId: null,
};

SearchPageComponent.propTypes = {
  listings: array,
  mapListings: array,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
  } = state.SearchPage;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  // Order map for priority IDs to maintain their original sequence
  const priorityOrderMap = {};
  PRIORITY_VEHICLE_IDS.forEach((id, index) => {
    priorityOrderMap[id] = index;
  });

  // Collect all priority listings that exist in pageListings
  const foundPriorityListings = pageListings.filter(listing =>
    PRIORITY_VEHICLE_IDS.includes(listing.id.uuid)
  );

  // Sort according to the order in the PRIORITY_VEHICLE_IDS array
  const sortedPriorityListings = foundPriorityListings.sort((a, b) => {
    return priorityOrderMap[a.id.uuid] - priorityOrderMap[b.id.uuid];
  });

  // Get all non-priority listings
  const otherListings = pageListings.filter(
    listing => !PRIORITY_VEHICLE_IDS.includes(listing.id.uuid)
  );

  const sortedListings = [...sortedPriorityListings, ...otherListings];

  return {
    listings: sortedListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
